// modal open/close
const modal = document.querySelector('.modal');
if (modal) {
    const openModalButtons = document.querySelectorAll('.open-modal');
    const body = document.getElementsByTagName('body')[0];
    openModalButtons.forEach((openBtn) => {
        openBtn.addEventListener('click', function (e) {
            e.preventDefault();

            if (openBtn.dataset.title) {
                dataLayer.push({
                    event: 'bookingStart',
                    experience: openBtn.dataset.title,
                    location: openBtn.dataset.location,
                    experienceType: openBtn.dataset.type,
                });
            }

            if (modal.classList.contains('is-active')) {
                modal.classList.remove('is-active');
                body.classList.remove('prevent-scroll');
                document.dispatchEvent(new Event('modal:closed'));
            } else {
                modal.classList.add('is-active');
                body.classList.add('prevent-scroll');
                document.dispatchEvent(new Event('modal:opened'));
            }
        });
    });

    const closeButtons = modal.querySelectorAll('.close');
    closeButtons.forEach((closeBtn) => {
        closeBtn.addEventListener('click', function (e) {
            e.preventDefault();
            modal.classList.remove('is-active');
            body.classList.remove('prevent-scroll');
            document.dispatchEvent(new Event('modal:closed'));
        });
    });
}

// Open modal on a mobile device after QR code scan
if (window.location.search.includes('modal')) {
    // wait Vue to generate component
    setTimeout(function () {
        modal.classList.add('is-active');
        document.body.classList.add('prevent-scroll');
        document.dispatchEvent(new Event('modal:opened'));
    }, 1000);
}
