// Lazy-loads reCAPTCHA on forms with a data-recaptcha attribute when a user interacts with form inputs.
const forms = document.getElementsByTagName('form');
Array.from(forms).forEach((form) => {
    // form doesn't have recaptcha
    if (!form.dataset.recaptcha) return;
    // recaptcha is already loaded
    let recaptchaIsLoaded = false;
    Array.from(form.elements).forEach((element) => {
        element.addEventListener('input', () => {
            if (!recaptchaIsLoaded) {
                document.querySelectorAll('script[data-recaptcha]').forEach((script) => {
                    script.type = 'text/javascript';
                    document.head.append(script);
                    recaptchaIsLoaded = true;
                });
            }
        });
    });
});
