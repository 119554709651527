// import _ from 'lodash';
// window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/*window.axiosApi = axios.create({
    baseURL: document.head.dataset.api,
    withCredentials: true,
});*/

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
/**
 * Set language/currency globally on "window" object
 */
window.lang = document.documentElement.lang.substring(0, 2);

window.currency = document.body.dataset.currency;
window.currencyCode = document.body.dataset.currencyCode;



// Vite - process images and fonts inside resources
import.meta.glob([
    '../images/**',
    '../fonts/**',
]);
