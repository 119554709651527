// home page - categories & testimonials
const customCarousels = document.getElementsByName('custom-carousel');
if (customCarousels) {
    customCarousels.forEach((carousel) => {
        document.addEventListener('DOMContentLoaded', function () {
            import('@splidejs/splide').then((Splide) => {
                new Splide.default(carousel, {
                    type: 'loop',
                    pagination: false,
                    perPage: 4,
                    perMove: 1,
                    autoplay: 'play',
                    arrowPath:
                        'M30.1708 18.6167C30.1795 18.6248 30.1881 18.633 30.1967 18.6411C31.2678 19.6677 31.2678 21.3323 30.1967 22.3589L14.6824 37.23C13.6114 38.2567 11.8748 38.2567 10.8038 37.23C9.73277 36.2034 9.73277 34.5389 10.8038 33.5123L24.3785 20.5003L10.8033 7.48767C9.73224 6.4611 9.73224 4.79676 10.8033 3.76993C11.8743 2.74336 13.6108 2.74336 14.6819 3.76993L30.1708 18.6167Z',
                    classes: {
                        arrows: 'splide-custom-arrow',
                    },
                    breakpoints: {
                        1024: {
                            perPage: 2,
                        },
                        640: {
                            perPage: 1,
                        },
                    },
                }).mount();
            });
        });
    });
}

if (document.getElementById('testimonials-carousel')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('@splidejs/splide').then((Splide) => {
            new Splide.default('#testimonials-carousel', {
                type: 'loop',
                autoplay: true,
                interval: 9000,
            }).mount();
        });
    });
}

// newsletter form
(function () {
    const newsletterForm = document.getElementById('newsletterForm');
    if (newsletterForm) {
        newsletterForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const email = this.elements['email'].value;
            const recaptcha = this.elements['recaptcha'].value;
            const newsletterMessage = document.querySelector('.newsletter-message');
            newsletterMessage.style.display = 'none';
            newsletterMessage.classList.remove('error');
            axios
                .post('/api/subscriptions/general', {
                    email,
                    recaptcha,
                })
                .then((response) => {
                    dataLayer.push({
                        event: 'newsletterConfirmed',
                    });
                    newsletterMessage.innerText = newsletterMessage.dataset.success;
                })
                .catch((err) => {
                    const { data } = err.response;
                    newsletterMessage.innerText = data?.message ? data.message : newsletterMessage.dataset.error;
                    newsletterMessage.classList.add('error');
                })
                .finally(() => (newsletterMessage.style.display = 'block'));
        });
    }
})();
