import './bootstrap';
import './navigation';
import './hero';
import './modal';
import './recaptcha';
import './homepage';
import './ai-search';
import './detect-ie';

window.dataLayer = window.dataLayer || [];
