// Hero Slider
(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const desktopImages = document.querySelectorAll('.bg-image-desktop');
        const mobileImages = document.querySelectorAll('.bg-image-mobile');

        setupImageSlider(desktopImages, 6000);
        setupImageSlider(mobileImages, 6000);

        function setupImageSlider(images, interval) {
            if (images.length === 0) return;

            let currentImageIndex = getRandomIndex(images.length);

            function showImage(index) {
                images.forEach((image, i) => {
                    if (i === index) {
                        image.classList.add('active');
                    } else {
                        image.classList.remove('active');
                    }
                });
            }

            function nextImage() {
                currentImageIndex = (currentImageIndex + 1) % images.length;
                showImage(currentImageIndex);
            }

            setInterval(nextImage, interval);
            showImage(currentImageIndex);
        }

        function getRandomIndex(max) {
            return Math.floor(Math.random() * max);
        }
    });
})();
